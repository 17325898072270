import { deserializeFromClientToSimplifiedFormat } from "~/utils/error";
import { useSupabaseFunction } from "./useSupabaseFunction";

export const useSlackApi = createSharedComposable(function () {
  const { teamMember, refetch } = useUserMe();
  const { addToastError, addToast, addToastSuccess } = useToast();
  const supabase = useSupabase();

  const loadingSlackGetMyId = ref(false);
  const slackGetMyId = async function () {
    const { invoke } = useSupabaseFunction();

    loadingSlackGetMyId.value = true;
    const response = await invoke("slack_get_my_id", "GET", undefined);
    loadingSlackGetMyId.value = false;

    return response;
  };

  // We do not want to call this function multiple times at the same time
  // So the first call run the real function, the others just wait for the first to finish
  let getAndUpdateUserSlackIdPromise: Promise<void> | null = null;
  const getAndUpdateUserSlackId = async () => {
    if (getAndUpdateUserSlackIdPromise) return getAndUpdateUserSlackIdPromise;

    getAndUpdateUserSlackIdPromise = _getAndUpdateUserSlackId();

    try {
      await getAndUpdateUserSlackIdPromise;
    } finally {
      getAndUpdateUserSlackIdPromise = null;
    }
  };
  const _getAndUpdateUserSlackId = async () => {
    if (!teamMember.value) return addToastError({ title: "Impossible de récupérer tes informations" });

    addToast({
      type: "warning",
      title: "Ton compte n'est pas encore relié à Slack",
      description: "On va le faire pour toi, ça ne prendra que quelques secondes...",
      duration: 10000,
    });

    const result = await slackGetMyId();

    if (!result.success) {
      // TODO: check if error is from our side (500)
      const error = await deserializeFromClientToSimplifiedFormat(result.error);
      logger.error("deserializeFromClientToSimplifiedFormat", error, result.error);

      addToastError(
        {
          description: "Impossible de récupérer ton compte Slack, est-ce que tu t'es déjà connecté dessus ?",
        },
        error
      );
    } else {
      const { error } = await supabase
        .from("team_member")
        .update({ slack_id: result.data.slack_id })
        .eq("id", teamMember.value.id);
      if (error) {
        addToastError(
          {
            title: "Erreur de mise à jour de ton compte back office",
          },
          error
        );
      } else {
        addToastSuccess({
          title: "Ton compte a bien été relié à Slack !",
          description: "Tu pourras maintenant recevoir des notifications directement sur Slack",
          duration: 10000,
        });

        // So everyone have the new information
        await refetch();
      }
    }
  };

  return {
    slackGetMyId,
    loadingSlackGetMyId,

    getAndUpdateUserSlackId,
  };
});
